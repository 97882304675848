
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { captureException, withScope } from '@sentry/nextjs';
import { addApolloState, GetAllPageSlugsForCatchAllRouteDocument, GetAllPageSlugsForCatchAllRouteQuery, initializeApollo, Seo_Swnz_SeoFragment, useGetPageCollectionBySlugQuery, } from 'content-service';
import { breadcrumbQuery, footerQuery, headerQuery, pageContentQuery, standardPageHeroQuery, } from 'content-service/src/helpers';
import { connectorElementQueries } from 'content-service/src/helpers/connectorElementQueries';
import { GetStaticPaths, GetStaticPathsContext, GetStaticPathsResult, GetStaticProps } from 'next';
import { CONTENT_SERVICE } from 'src/constants';
import { useTrackPageViews } from 'tracking';
import { BreadcrumbNav, createBreadcrumbNavBasePath, Error as ErrorComponent, PageSeo } from 'ui';
import { getQueryLocale } from 'utils';
import { addPageSlugToArr, buildPageQueryInput, getSlug } from 'utils/src/helpers/slug';
import PageModal from '../templates/PageModal';
import StandardPage from '../templates/StandardPage';
interface PageProps {
    slug?: string;
    preview: boolean;
    pathArray: string[];
    locale: string;
    pageSeo: Seo_Swnz_SeoFragment;
    pageTheme?: string;
}
export default function Page({ slug, preview, pathArray, locale, pageSeo, pageTheme }: PageProps) {
    const { data, error } = useGetPageCollectionBySlugQuery({
        variables: {
            ...buildPageQueryInput(slug ?? ''),
            preview,
            locale
        }
    });
    const [page] = data?.swnz_pageCollection?.items ?? [];
    const tags = page?.content?.__typename === 'SWNZ_PageStandard' ? page?.content?.tags : undefined;
    useTrackPageViews({ pageName: pageSeo?.title ?? '', pageViewData: tags });
    if (error || !page) {
        withScope(function (scope) {
            scope.setExtras({ path: pathArray, error, page });
            captureException(new Error(`Slug Page Error slug ${pathArray?.toString()}`));
        });
        return <ErrorComponent preview={preview} title={slug} statusCode={404}/>;
    }
    return (<>
      <BreadcrumbNav pathArray={pathArray} preview={preview}/>
      {page?.content?.__typename === 'SWNZ_PageStandard' && (<StandardPage codeId={page.codeId} content={page.content} pageSeo={pageSeo} pageTheme={pageTheme}/>)}
      {page?.content?.__typename === 'SWNZ_PageModal' && (<>
          <PageSeo pageSeo={pageSeo}/>
          <PageModal content={page.content} isPage/>
        </>)}
    </>);
}
export const getStaticPaths: GetStaticPaths = async ({ locales = [] }: GetStaticPathsContext) => {
    const apolloClient = initializeApollo();
    let paths: GetStaticPathsResult['paths'] = [];
    try {
        const { data } = await apolloClient.query<GetAllPageSlugsForCatchAllRouteQuery>({
            query: GetAllPageSlugsForCatchAllRouteDocument
        });
        const pages = data?.swnz_pageCollection?.items ?? [];
        const pageSlugArrays: Array<Array<string>> = [];
        if (pages.length) {
            pages.forEach((page) => {
                if (!page)
                    return;
                const pageSlugArr: Array<string> = [];
                addPageSlugToArr(pageSlugArr, page);
                pageSlugArrays.push(pageSlugArr);
            });
        }
        if (pageSlugArrays.length) {
            paths = locales?.flatMap((locale) => pageSlugArrays.map((pageSlugArr) => {
                return { params: { slug: pageSlugArr }, locale };
            }));
        }
    }
    catch (error) {
        console.error('🚨 Error generating static paths in [...slug]', error);
        throw error;
    }
    return { paths, fallback: false };
};
const getStaticProps: GetStaticProps = async ({ locale, params, preview = false, defaultLocale, }) => {
    const queryLocale = getQueryLocale(locale, defaultLocale);
    const pathArray = typeof params?.slug === 'string' ? [params?.slug] : params?.slug;
    const slug = getSlug(params?.slug);
    const sectionCodeId = createBreadcrumbNavBasePath(params?.slug);
    const apolloClient = initializeApollo({ preview: preview });
    let showLcf = true;
    try {
        const pageContentQueryResponse = await pageContentQuery({
            apolloClient,
            slug,
            preview,
            locale: queryLocale
        });
        const { pageSeo, pageTheme, elementData } = pageContentQueryResponse;
        // The page doesn't exist in the CMS
        if (elementData === undefined) {
            return { notFound: true };
        }
        if (!pageSeo) {
            console.error(`🚨 Error in pageSeo response for page: ${slug}`);
        }
        const [...rest] = await Promise.allSettled([
            headerQuery({
                apolloClient,
                codeId: CONTENT_SERVICE.HEADER_CODE_ID,
                preview,
                locale: queryLocale
            }),
            breadcrumbQuery({ apolloClient, sectionCodeId, preview, locale: queryLocale }),
            standardPageHeroQuery({
                apolloClient,
                slug,
                preview,
                locale: queryLocale
            }),
            footerQuery({ apolloClient, preview, locale: queryLocale }),
        ]);
        rest.filter((queryResponse) => {
            if (queryResponse.status !== 'fulfilled') {
                console.error(`🚨 Error in query response for page: ${slug}`);
            }
        });
        if (pageContentQueryResponse?.showLcf === false) {
            showLcf = false;
        }
        if (elementData?.length) {
            await connectorElementQueries(apolloClient, elementData, queryLocale, preview);
        }
        else {
            console.error(`🚨 Error in pageContentQueryResponse response for page: ${slug}`);
            return { notFound: true };
        }
        return addApolloState(apolloClient, {
            props: { slug, pathArray, preview, locale: queryLocale, pageSeo, pageTheme, showLcf }
        });
    }
    catch (error) {
        console.error(`🚨 Error pregenerating page: ${slug}`);
        return { notFound: true };
    }
};

    async function __Next_Translate__getStaticProps__191e9d7d520__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/[...slug]',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__191e9d7d520__ as getStaticProps }
  