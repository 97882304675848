import { Box } from '@chakra-ui/react'
import { ModalPage_Swnz_PageModalFragment, Swnz_PageModal } from 'content-service'
import { ElementTypenames } from 'content-service/src/helpers/connectorElementQueries'
import { useEffect, useState } from 'react'
import { ElementSelect } from 'ui'
import { ImageLoadingPreference, SectionCollectionId } from 'utils'
import { elementContainsImage } from './helpers'

export interface PageModalProps {
  content: ModalPage_Swnz_PageModalFragment | Swnz_PageModal
  isPage: boolean
}

const PageModal = ({ content, isPage }: PageModalProps) => {
  const [modalElementsToRender, setModalElementsToRender] = useState<SectionCollectionId[]>([])
  let modalElements: SectionCollectionId[] = [...(content?.sectionsCollection?.items || [])]

  if (content?.hero) {
    modalElements = [content.hero, ...modalElements]
  }

  const createImageElementProps = (isFirstElement: boolean) => {
    const elementProps = {
      imageLoadingPreference: ImageLoadingPreference.LAZY,
    }

    if (isFirstElement) {
      elementProps.imageLoadingPreference = ImageLoadingPreference.EAGER
    }

    return elementProps
  }

  const addInModalProp = (typename: string) => {
    const addInModal: string[] = [ElementTypenames.ImpactText]
    return addInModal.includes(typename) ? { inModal: true } : {}
  }

  useEffect(() => {
    setModalElementsToRender(modalElements)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content])

  return (
    <>
      {modalElementsToRender.map((element: SectionCollectionId, index) => {
        const isFirstElement = index === 0
        const isLastElement = index === modalElementsToRender.length - 1
        const isValueComponent = element?.__typename === 'SWNZ_Value'
        const isImpactText = element?.__typename === 'SWNZ_ImpactText'
        const isBulletList = element?.__typename === 'SWNZ_BulletPointContainer'
        const hasImage = elementContainsImage(element?.__typename)

        const elementProps = {
          ...(hasImage ? createImageElementProps(isFirstElement) : {}),
          ...addInModalProp(element?.__typename ?? ''),
        }

        const defaultWrapperPaddingY = { base: '25px', md: '30px' }
        let wrapperStyles
        if (hasImage) {
          wrapperStyles = {
            '> div > div': {
              maxWidth: '1330px',
              width: '100%',
              mx: 0,
              px: 0,
              pt: isFirstElement
                ? isPage
                  ? { base: '0px', md: '60px' }
                  : 0
                : defaultWrapperPaddingY,
              pb: isFirstElement
                ? 0
                : isLastElement
                ? { base: '50px', md: '60px' }
                : defaultWrapperPaddingY,
            },
            figcaption: {
              mx: { base: 7, md: 10 },
            },
          }
        } else if (!isValueComponent) {
          wrapperStyles = {
            '> div > div': {
              boxSizing: { lg: 'content-box' },
              maxWidth: isImpactText || isBulletList ? '1330px' : '870px',
              width: '100%',
              mx: 'auto',
              pt: defaultWrapperPaddingY,
              pb: isLastElement ? { base: '50px', md: '60px' } : defaultWrapperPaddingY,
            },
            '> div > div > div > div': {
              gridColumn: { lg: 'span 12' },
            },
            ...(isImpactText
              ? {
                  '> div > div > div > div > div': {
                    justifyContent: 'center',
                  },
                  '> div > div > div > div > div > div': {
                    boxSizing: { lg: 'content-box' },
                    maxWidth: '870px',
                  },
                }
              : {}),
          }
        }

        return (
          <Box sx={wrapperStyles} key={index}>
            <ElementSelect
              key={`${element?.sys?.id} + ${index}`}
              id={element?.sys?.id ?? ''}
              typename={element?.__typename ?? ''}
              {...elementProps}
            />
          </Box>
        )
      })}
    </>
  )
}

export default PageModal
