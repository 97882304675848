import { Box } from '@chakra-ui/react'
import {
  HeroFeatured_Swnz_HeroFeaturedFragment,
  HeroFeatured_Swnz_HeroFeaturedFragmentDoc,
  Hero_Swnz_HeroFragment,
  Hero_Swnz_HeroFragmentDoc,
  Seo_Swnz_SeoFragment,
  StandardPage_Swnz_PageStandardFragment,
} from 'content-service'
import dynamic from 'next/dynamic'
import { ReactElement } from 'react'
import Hero from 'src/components/Hero'
import { ElementSelect, getHeroIntroWrapperBgColor, HeroIntroWrapper, PageSeo } from 'ui'
import { createHeroImageProps, SectionCollectionId, useFragment } from 'utils'

export interface StandardPageProps {
  content: StandardPage_Swnz_PageStandardFragment | null
  codeId: string | undefined | null
  pageSeo?: Seo_Swnz_SeoFragment
  pageTheme?: string
}

interface PageElements {
  elements: SectionCollectionId[]
  leadCaptureFormId?: string
  pageTheme?: string
}

const StoryModal = dynamic(() => import('ui/src/components/StoryModal/StoryModal'))

function validateSWNZHero(hero) {
  if (hero?.__typename === 'SWNZ_Hero') {
    return {
      ...hero,
      textThemeColour: undefined,
    }
  }

  if (hero?.__typename === 'SWNZ_HeroFeatured') {
    return {
      ...hero,
      backgroundColour: undefined,
      displayType: undefined,
    }
  }

  return undefined
}

function IntroElement({
  intro,
  pageTheme,
  leadCaptureFormId,
  textColor,
}: {
  intro: SectionCollectionId
  pageTheme?: string
  leadCaptureFormId?: string
  textColor?: string | null
}) {
  return intro?.sys.id && intro?.__typename ? (
    <ElementSelect
      id={intro.sys?.id}
      typename={intro.__typename}
      isIntroElement={true}
      pageTheme={pageTheme}
      leadCaptureFormId={leadCaptureFormId}
      textColor={textColor}
    />
  ) : null
}

function PageElements({ elements, leadCaptureFormId, pageTheme }: PageElements) {
  return (
    <>
      {elements.map((item, index) => {
        if (item?.sys.id && item?.__typename) {
          // prettier-ignore
          const { sys: { id }, __typename: typename } = item
          return (
            <ElementSelect
              id={id}
              typename={typename}
              key={index}
              isIntroElement={false}
              pageTheme={pageTheme}
              leadCaptureFormId={leadCaptureFormId}
            />
          )
        }
      })}
    </>
  )
}

function getLeadCaptureFormId(elements: SectionCollectionId[]) {
  const leadCaptureForm = elements.find((item) => item?.__typename === 'SWNZ_FormLeadCapture')
  return leadCaptureForm?.sys?.id
}

const StandardPage = ({
  content,
  pageSeo,
  codeId,
  pageTheme,
}: StandardPageProps): ReactElement | null => {
  const heroData = useFragment<Hero_Swnz_HeroFragment>({
    id: content?.hero?.sys?.id ?? '',
    typename: content?.hero?.__typename ?? '',
    fragment: Hero_Swnz_HeroFragmentDoc,
    fragmentName: 'hero_SWNZ_Hero',
  })
  const heroFeaturedData = useFragment<HeroFeatured_Swnz_HeroFeaturedFragment>({
    id: content?.hero?.sys?.id ?? '',
    typename: content?.hero?.__typename ?? '',
    fragment: HeroFeatured_Swnz_HeroFeaturedFragmentDoc,
    fragmentName: 'heroFeatured_SWNZ_HeroFeatured',
  })

  if (content == null) {
    return null
  }

  const unvalidatedHeroData =
    content?.hero?.sys?.id === heroData?.sys?.id ? heroData : heroFeaturedData
  const hero = validateSWNZHero(unvalidatedHeroData)

  const heroBackgroundColor = getHeroIntroWrapperBgColor(
    hero?.__typename,
    hero?.displayType,
    hero?.backgroundColour,
    hero?.textThemeColour
  )

  const heroDisplayType = `${codeId}`.includes('finder') ? `${codeId}` : `${hero?.displayType}`
  const metaImgSrc = createHeroImageProps(hero?.mediaItem).src
  const elements = content.sectionsCollection?.items ?? []
  const leadCaptureFormId = getLeadCaptureFormId(elements)

  return (
    <Box as="main">
      {pageSeo ? <PageSeo pageSeo={pageSeo} metaImgSrc={metaImgSrc} /> : null}
      <HeroIntroWrapper backgroundColor={heroBackgroundColor}>
        <Hero
          hero={hero}
          pageTheme={pageTheme}
          displayType={heroDisplayType}
          leadCaptureFormId={leadCaptureFormId}
        />
        <IntroElement
          intro={content.intro}
          pageTheme={pageTheme}
          leadCaptureFormId={leadCaptureFormId}
          textColor={hero?.textThemeColour}
        />
      </HeroIntroWrapper>
      <PageElements
        pageTheme={pageTheme}
        elements={elements}
        leadCaptureFormId={leadCaptureFormId}
      />

      <StoryModal key={content.sys.id} />
    </Box>
  )
}

export default StandardPage
