import { useTheme } from '@chakra-ui/react'
import {
  HeroFeatured_Swnz_HeroFeaturedFragment,
  StandardPage_Swnz_PageStandardFragment,
} from 'content-service'
import { HeroFeatured, HeroFeaturedProps, HeroSelect, OptimisedImage } from 'ui'
import {
  createCtaButton,
  createHeroFloatingCardProps,
  createHeroImageProps,
  createHeroVideoProps,
  IMAGE_SIZE_CONFIG,
} from 'utils'
import { useMediaQuery } from 'utils/src/helpers'

type HeroFeatured = HeroFeatured_Swnz_HeroFeaturedFragment
type Hero = Pick<StandardPage_Swnz_PageStandardFragment, 'hero'>

type HeroFeaturedComponent = HeroFeaturedProps & {
  leadCaptureFormId?: string
}

type HeroProps = Hero & {
  displayType: string
  pageTheme?: string
  leadCaptureFormId?: string
}

const createHeroFeaturedComponentProps = (
  hero: HeroFeatured,
  leadCaptureFormId?: string,
  isFocusedOnFaces?: boolean
): HeroFeaturedComponent => ({
  heading: hero?.heading || '',
  subHeading: hero?.subHeading || '',
  introText: hero?.introText || '',
  primaryCta: createCtaButton(hero?.primaryCta),
  secondaryCta: createCtaButton(hero?.secondaryCta),
  video: createHeroVideoProps(hero?.mediaItem),
  image: OptimisedImage({
    ...createHeroImageProps(hero?.mediaItem),
    imageSizeConfig: IMAGE_SIZE_CONFIG.FEATURED_HERO,
    objectPosition: '80%',
    loading: 'eager',
    blurOnLoad: false,
    onFocusBreakpoint: isFocusedOnFaces,
  }),
  heroFloatingCard: hero?.heroFloatingCard && createHeroFloatingCardProps(hero?.heroFloatingCard),
  leadCaptureFormId: leadCaptureFormId,
  textThemeColour: hero?.textThemeColour,
})

function Hero({ hero, leadCaptureFormId, displayType, pageTheme }: HeroProps) {
  const theme = useTheme()
  const isFocusedOnFaces = useMediaQuery(`(max-width: ${theme.breakpoints.md})`)

  if (hero?.__typename === 'SWNZ_HeroFeatured') {
    return (
      <HeroFeatured
        {...createHeroFeaturedComponentProps(hero, leadCaptureFormId, isFocusedOnFaces)}
      />
    )
  }

  if (hero?.__typename === 'SWNZ_Hero') {
    return (
      <HeroSelect
        pageTheme={pageTheme}
        displayType={displayType}
        hero={hero ?? undefined}
        leadCaptureFormId={leadCaptureFormId}
      />
    )
  }

  return null
}

export default Hero
